<template>
	<div>
		<div class="row justify-content-md-center" style="    padding: 20% 0 0 0px;">
			<div class="col-md-6" >
				<template>
					<div class="text-center">
								<img :src="ImgLogo" class="max-h-70px" alt="" /><br>
								  <b-spinner type="grow" style="width: 3rem; height: 3rem;margin: 20px 10px;" label="Spinning"></b-spinner>
								  
								  <b-spinner variant="primary" style="width: 3rem; height: 3rem;margin: 20px 10px;" type="grow" label="Spinning"></b-spinner>
								  
								  <b-spinner variant="success" style="width: 3rem; height: 3rem;margin: 20px 10px;" type="grow" label="Spinning"></b-spinner>
							</div>
				</template>
			</div>
		</div>
	</div>
</template>


<script>
import Cookie from "@/core/services/localstorage.module";

import axios from "axios";
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
export default {
	data: () => ({
		tab: null,
    }),
	async  mounted() {
		if(Cookie.GetCookie('currentuser') == "undefined" || Cookie.GetCookie('currentuser') == undefined){
			this.$router.push({ path: "login" });
		}
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = "https://app.sayarte.ch/api/";
		await this.DoLogin();
		await this.GroupeDriverManagement();
		await this.GroupeManagement();
		await this.GroupeNameManagement();
		await pause(3000);
		this.$router.push({ path: "overview" });
	},
	watch: {
		
	},
	computed: {
		ImgLogo() {
			return (
				process.env.BASE_URL + "media/tlmtk/sayartechpro-logo.png"
			);
		}
	},
	methods: {
		async GroupeManagement() {
			/*axios.defaults.headers["Content-Type"] =  "application/json";
			axios.defaults.withCredentials = true;
			axios.defaults.baseURL = "https://app.sayarte.ch/api/";*/
			
			return await axios.post("controleur/cars/get_groupe.php").then((response) => {
				console.log(response.data);
				Cookie.SetCookie('groupe_management',response.data);
			}).catch((error) => {    
				if (error.response) {
						if (error.response.status == 500) {
							Cookie.DeleteCookie();
							this.$router.push('/login');
						}
					}
			});
		},
		async GroupeDriverManagement() {
			return await axios.post("controleur/driver/get_groupe.php").then((response) => {
				console.log(response.data);
				Cookie.SetCookie('groupe_driver',response.data);
			}).catch((error) => {    
				if (error.response) {
						if (error.response.status == 500) {
							Cookie.DeleteCookie();
							this.$router.push('/login');
						}
					} 
			});
		},
		async GroupeNameManagement() {
			var mindata = Cookie.GetCookie('mindata');
			for (var k in mindata){
				mindata[k]["name_groupe"] = "-";
				var id_groupe = parseInt(mindata[k].id_groupe)
				if(!isNaN(id_groupe)){
					return "-";
				}
				var groupe_management = Cookie.GetCookie('groupe_management');
				for (var i in groupe_management){
					if(i == id_groupe){
						mindata[k]["name_groupe"] = groupe_management[i].name;
					}
				}
				return "-";
			}
			Cookie.SetCookie('mindata',mindata);
		},
		async DoLogin() {
			return await axios.post("controleur/core/callback.php").then((response) => {
				response = response.data;
				
				var struser = "{ id: '"+response.id_user+"',username: '"+response.nickname+"',fullName: '"+response.lastname+" "+response.firstname+"',emailAddresses: '"+response.email+"',isAdmin: 'false'}";
				var userdetails = JSON.stringify(eval("(" + struser + ")"));
				
				Cookie.SetCookie('currentuser',response.nickname);
				Cookie.SetCookie('loggedin',"true");
				Cookie.SetCookie('list_notifs',response.list_notifs);
				Cookie.SetCookie('datelr_alarm',parseInt(response.datelr_alarm)*1000);
				Cookie.SetCookie('picture',response.picture);
				Cookie.SetCookie('mindata',response.mindata);
				Cookie.SetCookie('idUsers',response.id_user);
				Cookie.SetCookie('email',response.email);
				Cookie.SetCookie('nickname',response.nickname+" "+response.lastname+" "+response.firstname);
				Cookie.SetCookie('firstname',response.firstname);
				Cookie.SetCookie('lastname',response.lastname);
				Cookie.SetCookie('lastname',response.lastname);
				Cookie.SetCookie('currentuserdetails',userdetails);
				Cookie.SetCookie('logo_url',response.logo_url);
				Cookie.SetCookie('name_customer',response.name_customer);
				Cookie.SetCookie('id_cust',response.id_cust);
				
				typeof (response.type) != "undefined" ? Cookie.SetCookie('typeClient',response.type ) :Cookie.SetCookie('typeClient','u' );
				Cookie.SetCookie('typeClient',response.type );
				if(response.type == "")
						Cookie.SetCookie('typeClient',"u");
				
			}).catch((error) => {    
				if (error.response) {
						if (error.response.status == 500) {
							Cookie.DeleteCookie();
							this.$router.push('/login');
						}
					}   
			});
		}
	},
}
</script>
